import { createApp } from "vue";
import App from "./App.vue";
// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import vant from "vant";
import "vant/lib/index.css";
import Router from "@/router";

// import axios from "axios";

// axios.defaults.baseURL = "http://123.57.204.29:8087";
//添加的配置
// axios.defaults.withCredentials = true;

// Router.beforeEach((to, from, next) => {
//   // chrome
//   document.body.scrollTop = 0;
//   // firefox
//   document.documentElement.scrollTop = 0;
//   // safari
//   window.pageYOffset = 0;
//   next();
// });

const app = createApp(App);
app.use(vant);
// app.use(ElementPlus);
app.use(Router);
// app.prototype.$axios = axios;
app.mount("#app");
