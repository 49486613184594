<template>
    <!-- <div  style="padding-top: 46px">
      <van-nav-bar
        title="位置分布"
        left-arrow
        @click-left="onClickBack"
        fixed
        safe-area-inset-top

    ></van-nav-bar> -->
 
    <!-- <div style="position: fixed;">

    </div> -->
      <!-- 顶部导航 -->
    <div class="main" style="padding-top: 46px">
      <van-nav-bar
        title="位置分布"
        left-arrow
        @click-left="onClickBack"
        fixed
        safe-area-inset-top
      ></van-nav-bar>
    </div>
    <div id="main" style="height: 450px; background-color: white; "></div>

    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <div v-show="list.length > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 0px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >今日厂外项目：{{list.length}}个 </span
          >
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            > 今日厂外打卡人数：{{ amountAll }}人</span
          >
          <!-- <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >发票金额合计:{{ moneytotal }}元</span
          > -->
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <span v-if='item.province == null && item.city == null' style="color: #1967FF">所在城市：暂时无法获取(需打卡人员开启手机定位)</span>
            <span v-else style="color: #1967FF">所在城市：{{ item.province }} {{item.city}}</span>
            <br />
            <span>项目编号：{{ item.projCode }}</span>
            <br />
            <span>项目名称：{{ item.projName }}</span>
            <br />
            <span>当前人数：{{ item.personCount }}人</span>
            <br />
          </div>
        </van-cell>
      </van-list>
    <!-- </van-pull-refresh> -->

  <!-- </div> -->
  
</template>

<script>

// import * as echarts from "echarts";

import { GetresourcedistributionList, Getresourcedistributionprovince } from "../../api";
import { Toast } from "vant";

export default {

render: function(createElement) {
  return createElement("div", {
    attrs: {
      id: "main"
    },
    style: {
      height: "600px"
    }
  });
},
//   name: "Map",
data(){
    return {
      //   dataList:[
      //     {name:"北京",value:177},
      //     {name:"天津",value:42},
      //     {name:"河北",value:102},
      //     {name:"山西",value:81},
      //     {name:"内蒙古",value:47},
      //     {name:"辽宁",value:67},
      //     {name:"吉林",value:82},
      //     {name:"黑龙江",value:66},
      //     {name:"上海",value:24},
      //     {name:"江苏",value:92},
      //     {name:"浙江",value:114},
      //     {name:"安徽",value:109},
      //     {name:"福建",value:116},
      //     {name:"江西",value:91},
      //     {name:"山东",value:119},
      //     {name:"河南",value:137},
      //     {name:"湖北",value:116},
      //     {name:"湖南",value:114},
      //     {name:"重庆",value:91},
      //     {name:"四川",value:125},
      //     {name:"贵州",value:62},
      //     {name:"云南",value:83},
      //     {name:"西藏",value:9},
      //     {name:"陕西",value:80},
      //     {name:"甘肃",value:56},
      //     {name:"青海",value:10},
      //     {name:"宁夏",value:18},
      //     {name:"新疆",value:67},
      //     {name:"广东",value:123},
      //     {name:"广西",value:59},
      //     {name:"海南",value:14},
      // ],
      dataList:[],
      max: 480,
      min: 9, // todo 
      maxSize4Pin: 100,
      minSize4Pin: 20,
      geoCoordMap: {},
      list : [],
      loading: false,
      finished: false,

      amountAll: 0,
    }
},
methods: {
  convertData(data){
  var res = [];
      for (var i = 0; i < data.length; i++) {
          var geoCoord = this.geoCoordMap[data[i].name];
          if (geoCoord) {
              res.push({
                  name: data[i].name,
                  value: geoCoord.concat(data[i].value),
              });
          }
      }
      return res;
  },
  onClickBack (){
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.webkit.messageHandlers.getDataFormVue.postMessage({
        title: "333", //vue给iOS传值
      });
    } else {
      window.android.onFinish();
    }
  },
  onRefresh() {
      setTimeout(() => {
        this.loading = true;
        this.finished = true;
        // this.getDataList();
      }, 1000);
    },
  getCurrentDate() {
    let now = new Date();
    let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
    let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    let today = now.getDate(); //获取当前日(1-31)
    let hour = now.getHours(); //获取当前小时数(0-23)
    let minute = now.getMinutes(); //获取当前分钟数(0-59)
    let second = now.getSeconds(); //获取当前秒数(0-59)
    let nowTime = '' 
    nowTime = year + '-' + this.fillZero(month) + '-' + this.fillZero(today)
    return nowTime
},
  // 给时间补零
  fillZero(str){
    var realNum;
    if (str < 10) {
        realNum = '0' + str;
    } else {
        realNum = str;
    }
    return realNum;
  },
  getDataList(){
    GetresourcedistributionList({
        strDate: this.getCurrentDate(),
        token: this.$route.query[1],
        userid: this.$route.query[0],
      })
      // 在Vue的组件中
        .then((result) => {
          this.dataList = [];
          if (result.code == 1) {
              this.loading = true;
              this.finished = true;
              this.amountAll = result.data.workerCount;
              // // 将新数据与老数据进行合并
              this.list.push(...result.data.projectList);
              // 循环
              var a = result.data.provinceList;
              a.forEach(element => {
                this.dataList.push({name: element.province.substring(0, element.province.length - 1) , value: element.personCount})
              });

              this.initEchart();
            }
        })
        .catch((err) => {
          console.log(err);
          this.amountAll = 0;
          // Toast("网络错误");
        });
  },
  getList(province){
    Getresourcedistributionprovince({
        strDate: this.getCurrentDate(),
        province: province,
        token: this.$route.query[1],
        userid: this.$route.query[0],
      })
      // 在Vue的组件中
        .then((result) => {
          var that = this;
          this.list = [];
          // this.dataList = [];
          if (result.code == 1) {
              this.amountAll = result.data.workerCount;
              console.log(that.amountAll);
              // // 将新数据与老数据进行合并
              this.list.push(...result.data.projectList);
            }
        })
        .catch((err) => {
          console.log(err);
          // console.log(222);
          this.amountAll = 0;
          // Toast("网络错误");
        });
  },
  jump(item){
      // 
      // if (item) { 
      //   let que = item;
      //   if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //     window.webkit.messageHandlers.tapLocationOrder.postMessage({
      //       title: que, //vue给iOS传值
      //     });
      //   } else {
      //     window.android.startActivity(item);
      //     // window.android.onFinish();
      //   }
      // }
      let que = item;
      console.log(que);
      if(que){
          if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.tapLocationOrder.postMessage({
            title: {city: que.city, province: que.province, projCode: que.projCode, projName: que.projName, projId: que.projId, personCount: que.personCount}, //vue给iOS传值
          });
        } else {

          window.android.toProDetails(JSON.stringify(que));
          // Toast('1111');
          // window.android.toProDetails({city: que.city, province: que.province, projCode: que.projCode, projName: que.projName, projId: que.projId, personCount: que.personCount});
        }
      }

  },

 async initEchart() {
      const that = this; 
      var myChart = echarts.init(document.getElementById('main'));
      var mapFeatures1 = echarts.getMap('china');
      console.log(myChart);
      var mapFeatures = echarts.getMap('china').geoJson.features;
      mapFeatures.forEach(function(v) {
          // 地区名称
          var name = v.properties.name;
          // 地区经纬度
          that.geoCoordMap[name] = v.properties.cp;
      });
      
      var option = {
            visualMap: {
              show:false,
              min: 1,
              max: 99,
              // calculable: true,
              // calculable: true,
              seriesIndex: 0,
              inRange: {
                color: ['rgb(56,96,245)']
              }
          },
          geo: {
              map: 'china',
              roam: true,//不开启缩放和平移
              zoom:1.23,//视角缩放比例
              label: {
                  normal: {
                      show: true,
                      fontSize:'10',
                      color: 'rgba(0,0,0,0.7)'
                  }
              },
              itemStyle: {
                  normal:{
                      borderColor: 'rgba(0, 0, 0, 0.2)'
                  },
                  emphasis:{
                      areaColor: 'yellow',//鼠标选择区域颜色
                      shadowOffsetX: 0,
                      shadowOffsetY: 0,
                      shadowBlur: 20,
                      borderWidth: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          },
          // 鼠标悬浮提示框
          series : [
              {
                  type: 'map',
                  map: 'china',
                  geoIndex: 0,
                  aspectScale: 0.75, //长宽比
                  showLegendSymbol: false, // 存在legend时显示
                  label: {
                      normal: {
                          show: true
                      },
                      emphasis: {
                          show: false,
                          textStyle: {
                              color: '#fff'
                          }
                      }
                  },
                  roam: true,
                  itemStyle: {
                      normal: {
                          areaColor: '#031525',
                          borderColor: '#3B5077',
                      },
                      emphasis: {
                          areaColor: '#2B91B7'
                      }
                  },
                  animation: false,
                  data: this.dataList
              },
              {
                  name: '点',
                  type: 'scatter',
                  coordinateSystem: 'geo',
                  symbol: 'pin', //气泡
                  symbolSize: function(val) {
                              var a = (100 - 20) / (480 - 9);
                              var b = 20 - a * 9;
                              b = 100 - a * 480;
                              return 28;
                          },
                  label: {
                      normal: {
                          show: true,
                          formatter: '{@value}',
                          textStyle: {
                              color: '#fff',
                              fontSize: 15,
                          },
                      },
                  },
                  itemStyle: {
                      normal: {
                          color: '#F62157', //标志颜色
                      }
                  },
                  zlevel: 6,
                  data: this.convertData(this.dataList),
              },
          ],
      };
      myChart.setOption(option);
      myChart.on('click', function (params) {
        if(params.name){
          that.getList(params.name);
          // that.getList();
        }
          // console.log(params.name);
      });
  },
},
};
</script>

<style scoped>

:deep(.van-tabs__line) {
  background: white !important;
}
.van-nav-bar {
  background-color: rgb(50, 92, 254);
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
/* .van-icon{
  color: white;
} */
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}

</style>