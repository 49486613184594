<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="ExternalLaborCostsDetails" style="margin-top: 45px">
    <van-nav-bar
      title="安装工费明细"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
    <div class="container">
        <van-cell-group class="topdiv">
        <van-cell
          ><span style="font-size: 16px"
            >项目编号：{{ projInfo.projCode == null || projInfo.projCode == '' ? '暂无': projInfo.projCode }}</span
          >
          <br />
          <span style="font-size: 16px"
            >项目名称：{{ projInfo.projName == null || projInfo.projName == '' ? '暂无': projInfo.projName }}</span
          >
          <br />
          <span style="font-size: 16px"
            >安装日期：{{ projInfo.startDate }}至{{ projInfo.endDate }}</span
          >
          <br />
          <span style="font-size: 16px"
            >项目经理：{{ projInfo.productionManager == null || projInfo.productionManager == '' ? '暂无': projInfo.productionManager }}</span
          >
          <br />
          <span style="font-size: 16px"
            >安装带队人：{{ projInfo.leaderName  == null || projInfo.leaderName  == '' ? '暂无': projInfo.leaderName }}</span
          >
          <br />
          <span style="color: rgb(50, 92, 254);  font-size: 16px"
            >总定额：{{ projInfo.strInstallationQuota  == null || projInfo.strInstallationQuota  == '' ? '0': projInfo.strInstallationQuota }}元</span
          >
        </van-cell>
      </van-cell-group>
      <div class="mainTable">
        <table>
          <thead class="fixed-header">
              <th :class="index == 1 ?'thBlue-class' :'th-class'" v-for="(user, index) in users" :key="user">{{ user }}</th>
          </thead>
          <tbody>
            <td v-for="(user, index) in users" :key="user" v-if="users.length <= 3">
              <tr v-if="index == 0" class="tr140width" v-for="item in items[index]" :key="item">{{ item }}</tr>
              <tr v-if="index != 0" v-for="item in items[index]" :key="item">{{ item }}</tr>
            </td>
            <td v-for="(user, index) in users" :key="user" v-if="users.length > 3">
              <tr v-if="index == 0" class="tr140width" v-for="item in items[index]" :key="item">{{ item }}</tr>
              <tr v-if="index != 0" class="tr100width" v-for="item in items[index]" :key="item">{{ item }}</tr>
            </td>
          </tbody>
        </table>
    </div>
    </div>   
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ExternalcostProjdetail } from "../../api.js";
import { Toast } from "vant";

export default {
  data: function () {
    return {
      users: ["安装工费"],
      items: [],
      i_leftItmeData:[],
      projInfo: {},
    };
  },
  methods: {
    getDataDetails() {
      const that = this;
      ExternalcostProjdetail({
        userid: that.$route.query[0],
        token: that.$route.query[1],
        projid: that.$route.query[2],
        costid: that.$route.query[4],
        // projid: 4032,
        // costid: 1,
      })
        .then((result) => {
          if (result.code == 1) {
            // 顶部
              let i_users = result.data.userNames;
              i_users.forEach((item) => {
                that.users.push(item);
              })
              // 追加左侧
              let strDates = result.data.strDates;
              that.i_leftItmeData.push("在途去工时");
              strDates.forEach((item) => {
                that.i_leftItmeData.push(item);
              })
              that.i_leftItmeData.push("在途回工时");
              // that.i_leftItmeData.push("原工时小计");
              that.i_leftItmeData.push("工时小计");
              that.i_leftItmeData.push("基本工资");
              that.i_leftItmeData.push("安装工资");
              that.i_leftItmeData.push("奖励评分");
              that.i_leftItmeData.push("奖励分配1");
              that.i_leftItmeData.push("奖励分配2");
              that.i_leftItmeData.push("奖励工资合计");
              that.i_leftItmeData.push("带队补助工时");
              that.i_leftItmeData.push("报工报票工时");
              that.i_leftItmeData.push("带队补助工资");
              that.i_leftItmeData.push("过节补助费用");
              that.i_leftItmeData.push("安装工资合计");
              that.items.push(that.i_leftItmeData);
              // 追加左侧
              let wages = result.data.wages;
              wages.forEach((item) => {
                that.items.push(item);
              })
              that.projInfo = result.data.projInfo;

              // that.users.splice(2,12);
              // that.projInfo.splice(2,12);
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    onClickBack (){
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.webkit.messageHandlers.getDataFormVue.postMessage({
        title: "333", //vue给iOS传值
      });
    } else {
      window.android.onFinish();
    }
  },
  },
  // 获取详情
  mounted: function () {
    this.getDataDetails();
  },

};
</script>

<style scoped>
.projectName {
  color: rgb(50, 92, 254);
}
:deep(.van-tabs__line) {
  background: white !important;
}

.van-nav-bar {
  background-color: rgb(50, 92, 254);
}

:deep(.van-nav-bar .van-icon) {
  color: white !important;
}

:deep(.van-nav-bar__title) {
  color: white !important;
}

:deep(.van-nav-bar__text) {
  color: white !important;
}
table {
  min-width: 100%;
  border-collapse: collapse;
  border-top: solid 1px darkgray;
  border-left: solid 1px darkgray;
}
.th-class {
  height: 44px;
  background: rgb(212,212,212);
  justify-content: center;
  align-items: center;
  border-right: solid 1px darkgray;
  border-bottom: solid 1px darkgray;

}
.thBlue-class {
  /* width:44px; */
  height: 44px;
  background: rgb(212,212,212);
  justify-content: center;
  align-items: center;
  border-right: solid 1px darkgray;
  border-bottom: solid 1px darkgray;
  color:  rgb(50, 92, 254);
}
td {
  border-collapse: collapse;
  border-spacing: 0px;
  border-right: solid 1px darkgray;
}
tr {
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 14px;
  border-spacing: 0px;
  border-bottom: solid 1px darkgray;
 }
 .tr140width {
  width: 140px;
 }
 .tr100width {
  width: 100px;
 }
th:first-child {
    background: rgb(212,212,212);
    position: sticky;
    left: 0;
    width:140px;
}
td:first-child
{
  border-right: solid 1px darkgray;
  background: #f0f0f0;
  position: sticky;
  left: 0;
}
.fixed-header {
  background-color: white;
}
.container {
  position: relative;
}
.topdiv {
  left: 0;  
  overflow: auto;
}
.mainTable {
  padding-top: 20px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
