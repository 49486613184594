<template>
  <div class="OrderList" style="padding-top: 45px">
    <van-nav-bar
      v-if="roleid != 3"
      title="费用明细"
      left-arrow
      @click-left="onClickBack"
      @click-right="onClickRight"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <van-nav-bar
      v-if="roleid == 3"
      title="费用明细"
      left-arrow
      :right-text="projStatus == 2 ? '' : '录入'"
      @click-left="onClickBack"
      @click-right="onClickRight"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div>
          <div
            style="margin-top: 10px; margin-bottom: 10px; text-align: center"
          >
            <span
              style="
                color: rgb(150, 151, 153);
                font-size: 14px;
                margin-right: 10px;
              "
              >今日录入：{{ toDayEntry }}项</span
            >
            <span style="color: rgb(150, 151, 153); font-size: 14px"
              >今日费用合计：{{ toDayAmount }}元</span
            >
          </div>
          <div style="text-align: center; margin-bottom: 10px">
            <span
              style="
                color: rgb(150, 151, 153);
                font-size: 14px;
                margin-right: 10px;
              "
              >总计录入：{{ totalOfEntry }}项</span
            >
            <span style="color: rgb(150, 151, 153); font-size: 14px"
              >合计费用：{{ loanAmount }}元</span
            >
          </div>
        </div>

        <van-cell v-for="(item, index) in list" :key="item">
          <div>
            <van-uploader
              v-model="item.urlList"
              multiple
              :deletable="false"
              :show-upload="false"
            />
            <br />
            <span>【{{ item.typeName }}】</span>
            <span>{{ item.remark }}</span>
            <br />
            <div v-if="item.typeId != 7">
              <span>金额：{{ item.amount }}元</span>
              <br />
            </div>
            <span>录入人：{{ item.createUser }}</span>
            <br />
            <span>录入时间：{{ formatDate(item.createTime) }}</span>
            <br />
            <span>状态：</span
            ><span v-if="item.status == 1" style="color: #2671fe">未归档</span>
            <span v-if="item.status == 2" style="color: #4caf50">已归档</span>
            <span v-if="item.status == 3" style="color: #ff0030">已退回</span>
            <div v-if="roleid == 3">
              <div v-if="item.status != 2">
                <van-button
                  color="#2671fe"
                  type="default"
                  size="small"
                  style="margin-right: 10px; border-radius: 10px; width: 80px"
                  @click="clickChange(item)"
                  >修改</van-button
                >
                <van-button
                  style="border-radius: 10px; width: 80px"
                  type="danger"
                  size="small"
                  @click="clickDelete(index, item.id)"
                  >删除</van-button
                >
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-dialog
      v-model:show="showevaldialog"
      message="确认删除吗？"
      show-cancel-button
      confirm-button-text="是"
      cancel-button-text="否"
      @confirm="deleteSure"
    >
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { getinstallationdetail, deletecostinfo } from "../api";
export default {
  name: "MapContainer",
  data() {
    return {};
  },
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const projid = ref(route.query[2]);
    const token = ref(route.query[1]);
    const userid = ref(route.query[0]);
    const toDayEntry = ref(0);
    const totalOfEntry = ref(0);
    const loanAmount = ref(0);
    const toDayAmount = ref(0);
    const showevaldialog = ref(false);
    const projStatus = ref(0);
    const deleteId = ref(0);
    const deleteIndex = ref(0);
    const roleid = ref(0);

    const onLoad = () => {
      getinstallationdetail({
        token: token.value,
        userid: userid.value,
        projid: projid.value,
      })
        .then((result) => {
          list.value = [];
          if (refreshing.value) {
            refreshing.value = false;
          }
          list.value.push(...result.data.costInfoList);
          toDayEntry.value = result.data.toDayEntry;
          totalOfEntry.value = result.data.totalOfEntry;
          toDayAmount.value = result.data.toDayAmount;
          loanAmount.value = result.data.loanAmount;
          projStatus.value = result.data.projStatus;
          roleid.value = result.data.roleId;
          loading.value = false;
          if (result.data.hasNextPage) {
            console.log(result.data.nextPage);
            pageNum.value = result.data.nextPage;
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      toDayEntry,
      totalOfEntry,
      loanAmount,
      toDayAmount,
      showevaldialog,
      deleteId,
      deleteIndex,
      projid,
      projStatus,
      roleid,
    };
  },
  methods: {
    change(name) {
      console.log(name);
    },
    getCostType(type) {
      switch (type) {
        case 1:
          return "住宿费";
        case 2:
          return "长途交通费";
        case 3:
          return "市内交通费";
        case 4:
          return "复印费";
        case 5:
          return "材料费";
        case 6:
          return "其他";
        case 7:
          return "安装日志";
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        // let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        // let minutes =
        //   dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          //  +
          // hours +
          // ":" +
          // minutes
          // +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    clickDelete(index, id) {
      this.showevaldialog = true;
      this.deleteId = id;
      this.deleteIndex = index;
      // this.onLoad();
    },
    // 编辑
    clickChange(item) {
      // console.log(item.urlList);
      this.detailsData = item;
      this.detailsData.z_urlList = JSON.stringify(item.urlList);
      this.detailsData.z_urlsList = JSON.stringify(item.urlsList);
      this.detailsData.z_isLook = true;
      this.detailsData.z_token = this.$route.query[1];
      this.detailsData.z_userid = this.$route.query[0];
      this.detailsData.z_projid = this.$route.query[2];
      this.$router.push({
        path: "/RepairView",
        query: this.detailsData,
      });
      // console.log(item);
    },
    deleteSure() {
      deletecostinfo({
        token: this.token,
        userid: this.userid,
        costinfoid: this.deleteId,
      })
        .then((result) => {
          if (result.code == 1) {
            Toast(result.data);
            this.onLoad();
            // this.list.splice(this.deleteIndex, 1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    onClickRight() {
      this.detailsData = {};
      this.detailsData.z_isLook = false;
      this.detailsData.z_token = this.$route.query[1];
      this.detailsData.z_userid = this.$route.query[0];
      this.detailsData.z_projid = this.$route.query[2];
      this.$router.push({
        path: "/RepairView",
        query: this.detailsData,
      });
    },
  },
  mounted: function () {
    // Toast(222 + this.$route.query[0]);
  },
};
</script>

<style scoped>
.van-nav-bar {
  background-color: rgb(50, 92, 254);
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
