import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "./components/HomePage.vue";
import FlashPage from "./components/FlashPage.vue";
import RepairView from "./components/RepairView.vue";
import PerformanceAppraisalDetails from "./components/appraisal/PerformanceAppraisalDetails.vue";
import PerformanceAppraisalList from "./components/appraisal/PerformanceAppraisalList.vue";
import LocationDistribution from "./components/location/LocationDistribution.vue"
import ExternalLaborCostsDetails from "./components/externalCosts/ExternalLaborCostsDetails.vue"


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: FlashPage,
    },
    {
      path: "/homePage",
      component: HomePage,
    },
    {
      path: "/RepairView",
      component: RepairView,
    },
    {
      path: "/PerformanceAppraisalDetails",
      component: PerformanceAppraisalDetails,
    },
    {
      path: "/LocationDistribution",
      component: LocationDistribution,
    },
    {
      path: "/PerformanceAppraisalList",
      component: PerformanceAppraisalList,
    },
    {
      path: "/ExternalLaborCostsDetails",
      component: ExternalLaborCostsDetails,
    },
  ],
});

export default router;
