<template>
  <!-- 顶部导航 -->
  <div class="main" style="padding-top: 46px">
    <van-nav-bar
      title="评分详情"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
  </div>
  <van-notice-bar v-if="this.$route.query.z_state === '0' "
    wrapable
    :scrollable="false"
    text="评分项目必须全部进行打分才可提交，提交后不可修改，请谨慎操作。"
  />
  <!-- 基本信息 CD2403089GY-->
  <h5>项目基本信息</h5>
  <van-cell-group inset>
    <van-cell
      title="项目编号"
    >
      <template #value>
      <span class="custom-title">{{this.projInfo.projCode == null || this.projInfo.projCode == ''
          ? '暂无'
          : this.projInfo.projCode}}</span>
      </template>
    </van-cell>
    <van-cell
      title="项目名称"
      :value="
        this.projInfo.projName == null || this.projInfo.projName == ''
          ? '暂无'
          : this.projInfo.projName
      "
    ></van-cell>
    <van-cell v-if="this.isFold"
      title="项目类型"
      :value="
        this.projInfo.projType == null || this.projInfo.projType == ''
          ? '暂无'
          : this.projInfo.projType
      "
    />
    <van-cell v-if="this.isFold"
      title="运营经理"
      :value="
        this.projInfo.operationManager == null || this.projInfo.operationManager == ''
          ? '暂无'
          : this.projInfo.operationManager
      "
    />
    <van-cell v-if="this.isFold"
      title="项目经理"
      :value="
        this.projInfo.productionManager == null || this.projInfo.productionManager == ''
          ? '暂无'
    : this.projInfo.productionManager + '  ' + this.assistUserName
      "
    />
    <van-cell v-if="this.isFold"
      title="安装带队人"
      :value="
        this.projInfo.installationLeader == null || this.projInfo.installationLeader == ''
          ? '暂无'
          : this.projInfo.installationLeader
      "
    />
    <van-cell v-if="this.isFold"
      title="下单时间"
      :value="
        this.projInfo.orderTime == null || this.projInfo.orderTime == ''
          ? '暂无'
          : this.projInfo.orderTime
      "
    /> 
    <van-cell v-if="this.isFold"
      title="框架验收时间"
      :value="
        this.projInfo.frameTime == null || this.projInfo.frameTime == ''
          ? '暂无'
          : this.projInfo.frameTime
      "
    />
    <van-cell v-if="this.isFold"
      title="出厂验收时间"
      :value="
        this.projInfo.factoryTime == null || this.projInfo.factoryTime == ''
          ? '暂无'
          : this.projInfo.factoryTime
      "
    />
    <van-cell v-if="this.isFold"
      title="进场安装时间"
      :value="
        this.projInfo.installationTime == null || this.projInfo.installationTime == ''
          ? '暂无'
          : this.projInfo.installationTime
      "
    />
    <van-cell v-if="this.isFold"
      title="开业时间"
      :value="
        this.projInfo.openingTime == null || this.projInfo.openingTime == ''
          ? '暂无'
          : this.projInfo.openingTime
      "
    />
    <van-cell v-if="this.isFold"
      title="计划完工日期"
      :value="
        this.projInfo.planFinishTime == null || this.projInfo.planFinishTime == ''
          ? '暂无'
          : this.projInfo.planFinishTime
      "
    />
    <van-cell v-if="this.isFold"
      title="完工日期"
      :value="
        this.projInfo.colseTime == null || this.projInfo.colseTime == ''
          ? '暂无'
          : this.projInfo.colseTime
      "
    />
  </van-cell-group>
  <br>
  <!-- 展开收起按钮 -->
  <button style="width: 100%; height  44px; text-align: center; color: rgb(146, 147, 149); border: none;
    outline: none;
    border-radius: 0;
    background-color: transparent;
    line-height: inherit;
  " @click="changeExpend">{{this.isFold ? "收起":"查看更多"}}</button>
  <!-- 评分人&评分时间 -->
  <h5>评分人&评分时间</h5>
  <van-cell-group v-if="this.projInfo.scoreFlag === 0" inset>
      <van-cell
      title="项目经理"
    >
      <template #value>
      <span style="color:rgb(140, 141, 142)">{{this.projInfo.productionManager == null || this.projInfo.productionManager == ''
          ? '暂无'
          : this.projInfo.productionManager}}</span>
      </template>
    </van-cell>
    <van-cell
      title="上级领导"
    >
      <template #value>
      <span style="color:rgb(140, 141, 142)">{{this.projInfo.remark == null || this.projInfo.remark == ''
          ? '暂无'
          : this.projInfo.remark}}</span>
      </template>
    </van-cell>
  </van-cell-group>
  <!-- 评分人员-->
  <van-cell-group v-if="this.projInfo.scoreFlag === 1" inset>
      <van-cell
      title="项目经理"
    >
      <template #value>
      <span style="color:rgb(50, 92, 254)">{{this.projInfo.productionManager == null || this.projInfo.productionManager == ''
          ? '暂无'
          : this.projInfo.productionManager + '  ' + formatDate(this.managerScoreTime)}}</span>
      </template>
    </van-cell>
    <van-cell
      title="上级领导"
    >
      <template #value>
      <span style="color:rgb(140, 141, 142)">{{this.projInfo.remark == null || this.projInfo.remark == ''
          ? '暂无'
          : this.projInfo.remark}}</span>
      </template>
    </van-cell>
  </van-cell-group>
    <!-- 上级领导已评-->
    <van-cell-group v-if="this.projInfo.scoreFlag === 2" inset>
      <van-cell
      title="项目经理"
    >
      <template #value>
      <span style="color:rgb(50, 92, 254)">{{this.projInfo.productionManager == null || this.projInfo.productionManager == ''
          ? '暂无'
          : this.projInfo.productionManager + '  ' + formatDate(this.managerScoreTime)}}</span>
      </template>
    </van-cell>
    <van-cell
      title="上级领导"
    >
      <template #value>
      <span style="color:rgb(50, 92, 254)">{{this.projInfo.remark == null || this.projInfo.remark == ''
          ? '暂无'
          : this.projInfo.remark  + '  ' + formatDate(this.ministerScoreTime)}}</span>
      </template>
    </van-cell>
  </van-cell-group>

  <!-- 新 -->
  <div v-if="this.projInfo.scoreFlag === 0 && this.$route.query.z_state != '2'">
    <!-- <div>qqqq1</div> -->
    <div v-for="item in this.cfgScores" :key="item">
      <!-- <div>qqqq1</div> -->
    <!-- 一级列表 -->
    <h5>{{item.itemName }}</h5>
    <!-- 创建div -->
     <!-- <div>qqqq</div> -->
    <div style="background-color:white;">
      <div v-for="(secondItem, index) in item.cfgScores" :key="secondItem">
        <span class="perSpanTitle">{{ secondItem.itemName + '：' + secondItem.itemScore}}</span>
        <van-slider style="margin-left: 20px; padding-right: 20px; width: 80%;" v-model="secondItem.itemScore" :min="0" :max="100" :step="5" bar-height="4px" active-color="rgb(50, 92, 254)" @change="onChange(item,secondItem)">
          <template #button>
            <div class="custom-button">{{ secondItem.itemScore }}</div>
          </template>
        </van-slider>
        <br v-if="(item.cfgScores.length -1) === index">
      </div>
    </div>
   </div>
  </div>
  <!-- 分数查看 -->
  <div v-if="this.$route.query.z_state === '2' || this.$route.query.z_state === '1' || ( this.$route.query.z_state === '0' && this.projInfo.scoreFlag === 1)  ">
    <div v-for="item in this.cfgScores" :key="item">
    <!-- 一级列表 -->
    <h5 v-if='item.cfgScores.length > 0'>{{item.itemName}}</h5>
    <!-- 创建div -->
    <van-cell-group v-if='item.cfgScores.length > 0' inset>
      <!-- :title= "item.itemName + '-' + secondItem.itemName" -->
    <van-cell v-for="secondItem, in item.cfgScores" :key="secondItem"
      :title= "secondItem.itemName"
    >
      <template #value>
      <span :style="{'color':changeColor(secondItem.itemScore)}">{{secondItem.itemScore === null || secondItem.itemScore === '' || secondItem.itemScore === '-1'
          ? '-'
          : secondItem.itemScore}}</span>
      </template>
    </van-cell>
  </van-cell-group>
   </div>
  </div>
  <!-- 上级领导打分 -->
  <div v-if="this.projInfo.scoreFlag == 1">
    <div v-for="item in this.leaderCfgScores" :key="item">
    <!-- 一级列表 -->
    <h5>{{item.itemName}}</h5>
    <!-- 创建div -->
    <div style="background-color:white;">
      <div v-for="(secondItem, index) in item.cfgScores" :key="secondItem">
        <span class="perSpanTitle">{{ secondItem.itemName + '：' + secondItem.itemScore}}</span>
        <van-slider style="margin-left: 20px; padding-right: 20px; width: 80%;" v-model="secondItem.itemScore" :min="0" :max="100" :step="5" bar-height="4px" active-color="rgb(50, 92, 254)" @change="onChange(item,secondItem)">
          <template #button>
            <div class="custom-button">{{ secondItem.itemScore }}</div>
          </template>
        </van-slider>
        <br v-if="(item.cfgScores.length -1) === index">
      </div>
    </div>
   </div>
  </div>
  <!-- 按钮 -->
  <div v-if="this.$route.query.z_state === '0'">
    <van-button
      class="functionBtn"
      color="rgb(50, 92, 254)"
      round
      @click="adoptAction"
      >提交</van-button
    >
  </div>
  <div style="height: 20px;"></div>
</template>

<script>
import { Toast } from "vant";
import { projinfoscoreGetprojcfgscore, projinfoscoreScoreTheProject} from "../../api";
//   import { formatDate, roleName } from "../../js/xbTool";
import { Dialog } from "vant";

export default {
  setup() {
    // const onChange = (value) => Toast('当前分值：' + value);
    // return {
    //   onChange,
    // };
  },
  data: function () {
    return {
      projInfo: {},
      // 是否有效
      contractStatus: "",
      infoContract: {},
      partyA: {},
      partyB: {},
      dataList: ["", "", ""],
      //驳回
      bohuiShow: false,
      bohuiMessage: "",
      // 折叠展开按钮
      isFold: false,
      // 打分任务数组
      cfgScores: [],
      // 上级领导数组
      leaderCfgScores:[],
      // 项目经理打分时间
      managerScoreTime: "",
      // 上级领导打分时间
      ministerScoreTime: "",
      // 辅助项目经理
      assistUserName:"",
    };
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    getDataDetails() {
      const that = this;
      projinfoscoreGetprojcfgscore({
        projid: that.$route.query.id,
        token: that.$route.query.z_token,
        userid: that.$route.query.z_userid,
        scoreflag: that.$route.query.z_state,
        // token: "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI1IiwiZXhwIjoxOTgxMDcyMjQ2LCJpYXQiOjE2NjU0NTMwNDZ9.-54c7qJVTXNDBhrO_E0V7c0s5gy1GAiJ-Lz7-s1jGFM",
        // userid: 5,
        // token: this.$route.query.z_token,
        // userid: this.$route.query.z_userid,
      })
        .then((result) => {
          if (result.code == 1) {
            // 保存项目经理打分时间
            that.managerScoreTime = result.data.managerScoreTime;
            // 保存上级领导打分时间
            that.ministerScoreTime = result.data.ministerScoreTime;
            that.projInfo = result.data.projInfo;
            that.assistUserName = result.data.assistUserName;
            if (result.data.projInfo.scoreFlag === 0 || result.data.projInfo.scoreFlag === 2 || (result.data.projInfo.scoreFlag === 1 && that.$route.query.z_state === '0') || (result.data.projInfo.scoreFlag === 1 && that.$route.query.z_state === '1') || that.$route.query.z_state === '2') {
              // 循环数组 项目经理评分
              let cfgScoresArray = result.data.cfgScores;
              // 
              // console.log(1111);
              // console.log(cfgScoresArray);
              cfgScoresArray.forEach((item) => {
                if (that.$route.query.z_state === '2' || that.$route.query.z_state === '1') {
                    if (item.status != 0) {
                    //第一层
                    let cfgSecondScoresArray = item.cfgScores;
                    let newCfgSecondScoresArray = [];
                    cfgSecondScoresArray.forEach((secoundItem) => {
                      //第二层拼成对象且加入到数组里
                      let cfgScoresDict = { id: secoundItem.id, itemName: secoundItem.itemName, itemScore: secoundItem.score };
                      newCfgSecondScoresArray.push(cfgScoresDict);
                    })
                    // 讲新拼接的数组加入到第一层中
                    let scoresDict = { itemName: item.itemName, cfgScores: newCfgSecondScoresArray };
                    that.cfgScores.push(scoresDict);
                  }
                  // console.log(111111111);
                  // console.log(that.cfgScores);
                } else { 
                  // console.log(2222222222);
                    if (item.status != 0) {  
                      // console.log(3333333333);
                    //第一层
                      let cfgSecondScoresArray = item.cfgScores;
                      let newCfgSecondScoresArray = [];
                      cfgSecondScoresArray.forEach((secoundItem) => {
                        //第二层拼成对象且加入到数组里
                        let cfgScoresDict = { id: secoundItem.id, itemName: secoundItem.itemName, itemScore: secoundItem.score };
                        newCfgSecondScoresArray.push(cfgScoresDict);
                      })
                      // 讲新拼接的数组加入到第一层中
                      let scoresDict = { itemName: item.itemName, cfgScores: newCfgSecondScoresArray };
                      that.cfgScores.push(scoresDict);
                  }
                  console.log(that.cfgScores);
                }
              })
            }
            if (result.data.projInfo.scoreFlag === 1 && this.$route.query.z_state === '0') { 
              // 循环数组 上级领导评分
              let leaderScores = result.data.leaderScores;
              leaderScores.forEach((item) => {
                //第一层
                let leaderSecondScoresArray = item.cfgScores;
                let newleaderScoresArray = [];
                leaderSecondScoresArray.forEach((scoresItem) => {
                //第二层拼成对象且加入到数组里
                  let leaderSecoundScoresDict = { id: scoresItem.id, itemName: scoresItem.itemName, itemScore: scoresItem.score };
                  newleaderScoresArray.push(leaderSecoundScoresDict);
                })
                // 讲新拼接的数组加入到第一层中
                let leaderScoresDict = { itemName: item.itemName, cfgScores: newleaderScoresArray};
                that.leaderCfgScores.push(leaderScoresDict);
              })
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    // 通过
    adoptAction() {
      const that = this;
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "提交后不可修改，是否提交？",
      })
        .then(() => {
          // 循环拼接数组
          let cfgScoresArray = [];
          // 判断是谁评分
          if (that.projInfo.scoreFlag === 0) {
              that.cfgScores.forEach((item) => {
              let cfgScoresArr = item.cfgScores;
              cfgScoresArr.forEach((item) => {
                cfgScoresArray.push(item);
              })
            })
          } else { 
              that.leaderCfgScores.forEach((item) => {
              let cfgScoresArr = item.cfgScores;
              cfgScoresArr.forEach((item) => {
                cfgScoresArray.push(item);
              })
            })
          }
          // if (cfgScoresArray.length <= 0) { 
          //   Toast("暂无需要的评分");
          //   return;
          // }
          Toast.loading({
            duration: 0,
            message: "评分中...",
            forbidClick: true,
          });
          // 如果为空的话传空字符串过去
          
          if(cfgScoresArray.length <= 0){
            // console.log(1111111);
            projinfoscoreScoreTheProject({
            projid: that.$route.query.id,
            scorelist: "",
            token: that.$route.query.z_token,
            userid: that.$route.query.z_userid,
            // token: "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI1IiwiZXhwIjoxOTgxMDcyMjQ2LCJpYXQiOjE2NjU0NTMwNDZ9.-54c7qJVTXNDBhrO_E0V7c0s5gy1GAiJ-Lz7-s1jGFM",
            // userid: 5,
            // token: this.$route.query.z_token,
            // userid: this.$route.query.z_userid,
          })
            .then((result) => {
              // Toast.clear()
              if (result.code == 1) {
                // 退回2
                this.$router.go(-1);
                Toast(result.data);
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
          }else{
            console.log(22222222);
            projinfoscoreScoreTheProject({
            projid: that.$route.query.id,
            scorelist: JSON.stringify(cfgScoresArray),
            token: that.$route.query.z_token,
            userid: that.$route.query.z_userid,
            // token: "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI1IiwiZXhwIjoxOTgxMDcyMjQ2LCJpYXQiOjE2NjU0NTMwNDZ9.-54c7qJVTXNDBhrO_E0V7c0s5gy1GAiJ-Lz7-s1jGFM",
            // userid: 5,
            // token: this.$route.query.z_token,
            // userid: this.$route.query.z_userid,
          })
            .then((result) => {
              // Toast.clear()
              if (result.code == 1) {
                // 退回2
                this.$router.go(-1);
                Toast(result.data);
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    /* method */
    // 电话
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 时间转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        // let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        // let minutes =
        //   dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          //  +
          // hours +
          // ":" +
          // minutes
          // +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    roleName(item) {
      return roleName(item);
    },
   // 变化颜色
    changeColor(data) { 
      let color = '';
      if (data === '-') { 
        color = '#a0a0a0'
        return color;
      }
      let d = parseInt(data);
      if (d == 100) {
        color = '#325CFE'
      } else if (d > 60 && d < 100) { 
        color = '#a0a0a0'
      } else {
        color = '#b10e29'
      }
      return color;
    },
    // handleNull(data) { 
    //   let string = '';
    //   if()
    // }
    // 改变折叠展开
    changeExpend() {
      // console.log(this.cfgScores);
      this.isFold = !this.isFold;
    },
    // change
    onChange(item, secondItem) {
      Toast('当前值：' + secondItem.itemScore);
      // console.log(item);
      // console.log(secondItem);
    }
  },
  // 获取详情
  mounted: function () {
    console.log(this.$route.query);
    this.getDataDetails();
  },
};
</script>

<style scoped> 
:deep(.van-tabs__line) {
  background: white !important;
}
.van-nav-bar {
  background-color: rgb(50, 92, 254);
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
/* .van-icon{
  color: white;
} */
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
/* 基本 */ 
.custom-title {
 color:rgb(50, 92, 254);
}
/* h5 */
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  /* background-color: clear; */
}
.functionBtn {
  margin: 20px 20px 68px 20px;
  width: 90%;
}
.custom-button {
    width: 36px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    background-color:rgb(50, 92, 254);
    border-radius: 100px;
  }
.perSpanTitle {
  display:block;
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
  height: 30px;
}
</style>
  