<template>
  <div class="main" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="录入"
      left-arrow
      @click-left="onClickBack(result, title, moneyTitle)"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <!-- 报修类型 -->
    <br />
    <van-cell-group inset>
      <van-field
        v-model="result"
        is-link
        readonly
        label="类型"
        placeholder="请选择类型"
        @click="showPicker = true"
      />

      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>

    <br />

    <!-- 金额 -->
    <van-form @failed="onFailed" v-if="showFeiyong == true">
      <van-cell-group inset>
        <van-field
          v-model="moneyTitle"
          autosize
          name="pattern"
          :rules="[{ pattern, message: '请输入正确的金额' }]"
          label="金额（元）"
          type="number"
          maxlength="8"
          placeholder="请输入费用金额精确到小数点后两位"
          show-word-limit
        />
      </van-cell-group>
    </van-form>
    <br />
    <!-- 内容描述 -->
    <van-cell-group inset>
      <van-field
        v-model="title"
        rows="4"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注"
        maxlength="100"
        show-word-limit
      />
    </van-cell-group>

    <div>
      <h5>上传照片</h5>
      <h6>
        支持拍照或从相册选择照片上传（部分机型仅支持从相册选择），单张最大不超过20MB且最多9张照片
      </h6>
    </div>
    <!-- <div style="line-height: 0.4rem">{{ title.length }}/50</div> -->
    <!-- 提交按钮 -->
    <van-cell-group inset>
      <van-uploader
        class="preview-cover"
        v-model="photoList"
        :after-read="afterReadPhoto"
        :before-delete="beforeDeletePhone"
        :max-size="20 * 1024 * 1024"
        @oversize="onOversize"
        multiple
        :max-count="9"
      />
    </van-cell-group>
    <br />
    <van-button
      style="
        width: 95%;
        margin-left: 10px;
        margin-top: 50px;
        margin-bottom: 10px;
        position: fixed;
        left: 0px;
        bottom: 0;
        _position: absolute;
      "
      round
      type="primary"
      size="large"
      color="rgb(30, 110, 255)"
      @click="submit(result, reindex, title, moneyTitle)"
      :disabled="disable"
      >保存</van-button
    >
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { ref } from "vue";
// import { nil } from "_ajv@8.10.0@ajv";
import {
  OrderDeletephoto,
  costinfoAddcostinfo,
  costinfoGetcosttype,
  costinfoUpdatecostinfo,
} from "../api";
// import { compressImage } from "../components/config/utils";
import compressor from "compressorjs";
import axios from "axios";

export default {
  setup() {
    const result = ref("");
    const reindex = ref("");
    const showFeiyong = ref(true);
    const showPicker = ref(false);

    const onConfirm = (value, index) => {
      reindex.value = index;
      result.value = value;
      showPicker.value = false;
      if (value == "安装日志") {
        showFeiyong.value = false;
      } else {
        showFeiyong.value = true;
      }
    };

    const onFailed = (errorInfo) => {
      console.log("failed", errorInfo);
    };

    const value = ref("");
    const pattern = /^(0|[1-9][0-9]*)(\.\d+)?$/;

    return {
      result,
      reindex,
      onConfirm,
      showPicker,
      showFeiyong,
      value,
      onFailed,
      pattern,
    };
  },

  data() {
    return {
      title: "",
      moneyTitle: "",
      showVideo: false,
      viedeoUrl: "",
      videoImg: "",
      photoList: [],
      photoURLList: [],
      videoList: [],
      // videoList1: [],
      videoURLList: [],
      previewList: [],
      videoPlaceImage: "",
      columns: [],
      columnsid: [],
      disable: false,
      dateTime: "",
      // 测试
      // urlString: "https://capacityapi.dbshopplus.com",
      // 正式
      urlString: "https://api.cap.cdhayaretail.com",

    };
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("图片大小不能超过20M，请您重新拍照上传");
    },
    // 上传照片
    afterReadPhoto(file, index) {
      const that = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
      });
      if (file.length >= 1) {
        file.forEach((item, index) => {
          // console.log(item);
          const isImage = item.file.type.includes("image");
          if (!isImage) {
            Toast("上传文件类型必须是图片格式");
            that.photoList.splice(index.index, 1);
            return;
          }
          new compressor(item.file, {
            quality: 0.2,
            success(result) {
              const formData = new FormData();
              formData.append("photo", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  that.urlString + "/costinfo/uploadphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    if (file.length - 1 == index) {
                      Toast.clear();
                    }
                    // file.status = "done";
                    that.photoURLList.push(resultData.data.data);
                  } else {
                    if (file.length - 1 == index) {
                      Toast.clear();
                    }
                    // file.status = "failed";
                    // file.message = "上传失败";
                  }
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    Toast.clear();
                  }
                  console.log(err);
                });
              // console.log(result);
            },
          });
        });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        // console.log(file);
        const isImage = file.file.type.includes("image");
        if (!isImage) {
          Toast("上传文件类型必须是图片格式");
          that.photoList.splice(index.index, 1);
          return;
        }
        new compressor(file.file, {
          quality: 0.2,
          success(result) {
            const formData = new FormData();
            formData.append("photo", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(that.urlString + "/costinfo/uploadphoto", formData, config)
              .then((resultData) => {
                // console.log(result);
                Toast.clear();
                if (resultData.data.code == 1) {
                  file.status = "done";
                  that.photoURLList.push(resultData.data.data);
                } else {
                  file.status = "failed";
                  file.message = "上传失败";
                }
              })
              .catch((err) => {
                Toast.clear();
                console.log(err);
              });
          },
        });
      }
    },
    // 删除照片
    beforeDeletePhone(index, detail) {
      // console.log(this.beforeCounterPhotoList[detail.index]);
      // console.log(detail.index);
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(() => {
          OrderDeletephoto({
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            photourl: this.photoURLList[detail.index],
          })
            .then((result) => {
              if (result.code == 1) {
                Toast("删除成功");
                this.photoList.splice(detail.index, 1);
                this.photoURLList.splice(detail.index, 1);
              } else {
                this.photoList.splice(detail.index, 1);
                if (result.message) {
                  Toast(result.message);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    submit(result, reindex, title, moneyTitle) {
      console.log(this.photoURLList);
      console.log(result);
      if (result == "") {
        Toast("请选择类型");
        return;
      }
      if (title == "") {
        Toast("请输入备注");
        return;
      }
      if (this.photoURLList.length <= 0) {
        Toast("请上传照片");
        return;
      }
      if (result == "安装日志") {
        moneyTitle = "";
      } else {
        if (moneyTitle.match(this.pattern) == null || moneyTitle == 0) {
          Toast("请输入费用金额");
          return;
        }
      }
      this.disable = true;
      if (this.$route.query.z_isLook == "false") {
        Dialog.confirm({
          title: "",
          message: "是否确认录入内容已全部填写完成",
          confirmButtonText: "是",
          cancelButtonText: "否",
        })
          .then(() => {
            Toast.loading({
              message: "上传中...",
              forbidClick: true,
              duration: 0,
            });
            // if (this.beforeCounterPhotoList[detail.index].id) {
            costinfoAddcostinfo({
              projid: this.$route.query.z_projid,
              amount: moneyTitle,
              token: this.$route.query.z_token,
              userid: this.$route.query.z_userid,
              remark: title,
              ordertypeid: this.columnsid[reindex],
              urllist: this.photoURLList,
              // previewlist: this.previewList,
              // photoid: this.beforeCounterPhotoList[detail.index].id,
            })
              .then((result) => {
                this.disable = false;
                Toast.clear();
                if (result.code == 1) {
                  Toast(result.data);
                  Dialog.confirm({
                    title: "",
                    message: "录入提交成功，是否继续录入？",
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                  })
                    .then(() => {
                      // 置空
                      this.photoList = [];
                      this.photoURLList = [];
                      this.result = "";
                      this.reindex = 0;
                      this.title = "";
                      this.moneyTitle = "";
                    })
                    .catch(() => {
                      // 跳转上一级。
                      this.$router.go(-1);
                      // if (this.$route.query.repair == "repair") {
                      // } else {
                      //   if (
                      //     /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
                      //   ) {
                      //     window.webkit.messageHandlers.getDataFormVue.postMessage(
                      //       {
                      //         title: "333", //vue给iOS传值
                      //       }
                      //     );
                      //   } else {
                      //     window.android.onFinish();
                      //   }
                      // }
                    });
                } else {
                  Toast(result.message);
                }
              })
              .catch((err) => {
                this.disable = false;
                console.log(err);
              });
          })
          .catch(() => {
            this.disable = false;
          });
      } else {
        var ordertypeid = this.columnsid[reindex];
        // console.log(ordertypeid);
        if (ordertypeid == undefined) {
          // console.log(22222222);
          ordertypeid = this.$route.query.typeId;
        }
        // console.log(ordertypeid);
        Toast.loading({
          message: "上传中...",
          forbidClick: true,
          duration: 0,
        });
        // if (this.beforeCounterPhotoList[detail.index].id) {
        costinfoUpdatecostinfo({
          costinfoid: this.$route.query.id,
          projid: this.$route.query.z_projid,
          amount: moneyTitle,
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          remark: title,
          ordertypeid: ordertypeid,
          urllist: this.photoURLList,
          // previewlist: this.previewList,
          // photoid: this.beforeCounterPhotoList[detail.index].id,
        })
          .then((result) => {
            this.disable = false;
            Toast.clear();
            if (result.code == 1) {
              Toast(result.data);
              this.$router.go(-1);
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            this.disable = false;
            console.log(err);
          });
      }
    },
    // 获取类型
    OrderOrdertype() {
      costinfoGetcosttype({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          if (result.data.length > 0) {
            // console.log(result.data);
            result.data.forEach((item) => {
              // let parent = { id: item.id, url: item.typeName };
              this.columns.push(item.typeName);
              this.columnsid.push(item.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack(result, title, moneyTitle) {
      if (
        result != "" ||
        title != "" ||
        moneyTitle != "" ||
        this.photoURLList.length >= 1
      ) {
        Dialog.confirm({
          title: "",
          message: "您已填写录入内容确认取消录入吗？",
          confirmButtonText: "是",
          cancelButtonText: "否",
        })
          .then(() => {
            // 置空
            this.$router.go(-1);
            // if (this.$route.query.repair == "repair") {
            //   // 跳转上一级。
            //   this.$router.go(-1);
            // } else {
            //   if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            //     window.webkit.messageHandlers.getDataFormVue.postMessage({
            //       title: "333", //vue给iOS传值
            //     });
            //   } else {
            //     window.android.onFinish();
            //   }
            // }
          })
          .catch(() => {});
        return;
      } else {
        this.$router.go(-1);
        // if (this.$route.query.repair == "repair") {
        //   // 跳转上一级。

        // } else {
        //   if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //     window.webkit.messageHandlers.getDataFormVue.postMessage({
        //       title: "333", //vue给iOS传值
        //     });
        //   } else {
        //     window.android.onFinish();
        //   }
        // }
      }
    },
    //获取当前年月日时分秒
    getdateTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      let gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      return gettime;
    },
  },
  mounted: function () {
    this.OrderOrdertype();
    console.log(this.$route.query);
    // this.conformText();
    // this.title = ;
    if (this.$route.query.z_isLook == "true") {
      this.moneyTitle = this.$route.query.amount;
      this.title = this.$route.query.remark;
      this.result = this.$route.query.typeName;
      this.photoList = JSON.parse(this.$route.query.z_urlList);
      this.photoURLList = JSON.parse(this.$route.query.z_urlsList);
      if (this.$route.query.typeId == "7") {
        this.showFeiyong = false;
      }
    }
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background:#f8f8f8");
    });
  },
  beforeUnmount() {
    document.body.removeAttribute("style");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div .main {
  width: 100%;
  height: max-content;
  padding-top: 0px;
  background-color: #f8f8f8;
  padding-bottom: 100px;
}
.popCla {
  background: red;
  width: 340px;
  height: 240px;
}
div h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
}
div h6 {
  margin: 10px 20px 10px 20px;
  color: rgb(198, 202, 202);
  text-align: left;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
.van-nav-bar {
  background-color: rgb(50, 92, 254);
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
:deep(.van-uploader__preview-delete) {
  height: 24px;
  width: 24px;
}
:deep(.van-uploader__preview-delete-icon) {
  font-size: 24px;
}
/* 全局属性 */
/* .van-uploader__preview-delete {
  background: red;
  background-color: red;
  --van-uploader-delete-icon-size: 26px;
} */
</style>
