// 刚刚封装的axios
import request from "./axios";
import Qs from "qs"; // 传参需要用qs转换一下

// 订单列表
export const OrderList = (query) => {
  return request({
    url: "/order/orderlist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传单个图片
export const costinfoUploadphoto = (query) => {
  return request({
    url: "/costinfo/uploadphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传单个图片
export const OrderAddPH = (query) => {
  return request({
    url: "/order/testaddphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 创建报修单
export const OrderAddorder = (query) => {
  return request({
    url: "/order/addorder",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};

// 报修类型
export const OrderOrdertype = (query) => {
  return request({
    url: "/order/ordertype",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 删除单个图片
export const OrderDeletephoto = (query) => {
  return request({
    url: "/costinfo/deletephoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取费用类型
export const costinfoGetcosttype = (query) => {
  return request({
    url: "/costinfo/getcosttype",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 费用录入
export const costinfoAddcostinfo = (query) => {
  return request({
    url: "/costinfo/addcostinfo",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};

// 获取费用明细
export const getinstallationdetail = (query) => {
  return request({
    url: "/costinfo/getinstallationdetail",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 删除费用
export const deletecostinfo = (query) => {
  return request({
    url: "/costinfo/deletecostinfo",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 修改费用
export const costinfoUpdatecostinfo = (query) => {
  return request({
    url: "/costinfo/updatecostinfo",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};
// 项目详情
export const projinfoscoreGetprojcfgscore = (query) => {
  return request({
    url: "/projinfoscore/getprojcfgscore",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 项目打分
export const projinfoscoreScoreTheProject = (query) => {
  return request({
    url: "/projinfoscore/scoreTheProject",
    method: "post",
    data: Qs.stringify(query, { arrayFormat: "repeat" }),
  });
};
//获取需要打分和已打分的项目列表
export const Getprojlistbyuser = (query) => {
  return request({
    url: "/projinfoscore/getprojlistbyuser",
    method: "post",
    data: Qs.stringify(query),
  });
};
//获取项目总数
export const Getprojtotal = (query) => {
  return request({
    url: "/projinfoscore/getprojtotal",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 位置分布
export const GetresourcedistributionList = (query) => {
  return request({
    url: "/auth/getresourcedistribution",
    method: "post",
    data: Qs.stringify(query),
  });
};

export const Getresourcedistributionprovince = (query) => {
  return request({
    url: "/auth/getresourcedistributionprovince",
    method: "post",
    data: Qs.stringify(query),
  });
};

export const ExternalcostProjdetail = (query) => {
  return request({
    url: "/externalcost/projdetail",
    method: "post",
    data: Qs.stringify(query),
  });
};
