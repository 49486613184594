<template>
  <div class="PerformanceAppraisalList" style="margin-top: 45px">
    <van-nav-bar title="绩效考核" left-arrow @click-left="onClickBack" fixed safe-area-inset-top></van-nav-bar>
    <van-sticky sticky offset-top="45px">
      <van-search v-model="search" placeholder="请输入项目编号或名称搜索" @search="onSearch" />
    </van-sticky>
    <van-tabs v-model:active="active" swipeable sticky animated @change="change" ref="tabs" offset-top="99px"
      title-active-color="rgb(50, 92, 254)">
      <van-tab v-for="item in tabList" :key="item">
        <template #title>
          <span>{{ item.name }}</span>
          <van-tag color="rgb(50, 92, 254)" round type="primary" style="
              margin-left: 5px;
              height: 16px;
              width: 23px;
              text-align: center;
              color: white;
              display: inline-block;
              bottom: 0.5px;
            ">{{ parseInt(item.numbers) > 99 ? "99+" : item.numbers }}</van-tag>
        </template>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" v-model:error="error"
            error-text="请求失败，点击重新加载" @load="onLoad">
            <div v-show="total > 0">
              <span style="
                  text-align: center;
                  display: block;
                  font-size: 14px;
                  height: 18px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  color: rgb(151, 152, 154);
                ">{{ tabList[active].name }}项目数：{{ total }}</span>
            </div>
            <van-cell style="margin-right:0px" v-for="item in list" :key="item" @click="jump(item)">
              <span class="projectName"> {{ item.projCode }} </span><br />
              <p
                class="projectName"
                style="margin-top: 0px; margin-bottom: 0px"
              >
                {{ item.projName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                  item.productionManager
                }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.assistUserName }}
              </p >
              <div v-if="state == 2">
                <p class="projectName" style="margin-top: 0px; margin-bottom: 0px" v-if="item.scoreFlag == '2'">
                  {{ item.scoreFlag == "2" ? "已评分" : "未评分" }}
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px" v-if="item.scoreFlag != '2'">
                  {{ item.scoreFlag == "2" ? "已评分" : "未评分" }}
                </p>
              </div>

              <span>项目经理：{{ item.productionManager }}&nbsp;&nbsp;评分时间：{{
                  item.managerScoreTime == null
                    ? "暂无"
                    : formatDate(item.managerScoreTime)
              }}</span>
              <br />
              <span>上级领导：{{ item.productionMinister }}&nbsp;&nbsp;评分时间：{{
                  item.ministerScoreTime == null
                    ? "暂无"
                    : formatDate(item.ministerScoreTime)
              }}</span>
              <br />
              <van-tag round type="primary" color=" rgb(50, 92, 254)" style="margin-right: 5px"
                v-for="item in item.scoreVoList" :key="item">{{ item.itemName }} &nbsp;{{
                  item.score == null ? "-" : item.score
                }}&nbsp;</van-tag>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Getprojlistbyuser, Getprojtotal } from "../../api";
import { Toast } from "vant";
let route = useRoute();
let router = useRouter();
const search = ref("");
const active = ref(0);
const tabList = ref([
  {
    name: "全部",
    state: "2",
    numbers: 0,
    z_active: 0,
    z_userid: route.query[0],
    z_token: route.query[1],
  },
  {
    name: "已评分",
    state: "1",
    numbers: 0,
    z_active: 1,
    z_userid: route.query[0],
    z_token: route.query[1],
  },
  {
    name: "未评分",
    state: "0",
    numbers: 0,
    z_active: 2,
    z_userid: route.query[0],
    z_token: route.query[1],
  },
]);
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const error = ref(false);
const pageNum = ref(1);
const state = ref(2);
const userid = ref(route.query[0]);
const token = ref(route.query[1]);
const total = ref("");
const GetProjtotal = () => {
  Getprojtotal({ userid: userid.value, token: token.value })
    .then((result) => {
      console.log(result);
      tabList.value[0].numbers = result.data.allScoreTotal;
      tabList.value[1].numbers = result.data.alreadyScoreTotal;
      tabList.value[2].numbers = result.data.notScoreTotal;
    })
    .catch((err) => {
      console.log(err);
    });
};
const onLoad = () => {
  Toast.loading({
    message: "正在加载...",
    forbidClick: true,
    duration: 0,
  });
  Getprojlistbyuser({
    page: pageNum.value,
    size: 10,
    userid: userid.value,
    token: token.value,
    findfactor: search.value,
    scoreflag: state.value,
  })
    .then((result) => {
      Toast.clear();
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
      }
      if (result.code == 0) {
        total.value = 0;
      } else {
        list.value.push(...result.data.list);
        total.value = result.data.total;
        tabList.value[active.value].numbers = total.value;
      }
      loading.value = false;
      if (result.data.hasNextPage) {
        pageNum.value = result.data.nextPage;
      } else {
        finished.value = true;
      }
    })
    .catch((err) => {
      Toast.clear();
      console.log(err);
      error.value = true;
      loading.value = false;
      finished.value = true;
    });
};
const onRefresh = () => {
  // 清空列表数据
  finished.value = false;
  pageNum.value = 1;
  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  onLoad();
};
const onSearch = () => {
  pageNum.value = 1;
  list.value = [];
  state.value = tabList.value[active.value].state;
  onLoad();
};
const change = (index) => {
  console.log(index + "index");
  finished.value = false;
  pageNum.value = 1;
  list.value = [];
  state.value = tabList.value[active.value].state;
  // onLoad();
};
const jump = (item) => {
  // console.log(item);
  // console.log(state.value);
  const that = this;
  let detailsData = item;
  detailsData.z_token = token.value;
  detailsData.z_userid = userid.value;
  detailsData.z_state = state.value;
  router.push({
    path: "/PerformanceAppraisalDetails",
    query: detailsData,
  });
  // console.log(item);
};
// 时间戳转换
const formatDate = (date) => {
  // 获取单元格数据
  let data = date;
  if (data === "") {
    return "";
  } else {
    let dt = new Date(data);
    let month =
      dt.getMonth() + 1 < 10
        ? "0" + (dt.getMonth() + 1)
        : "" + (dt.getMonth() + 1);
    let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    return (
      dt.getFullYear() +
      "-" +
      // (dt.getMonth() + 1) +
      month +
      "-" +
      // dt.getDate() +
      day +
      " "
    );
  }
};
const onClickBack = () => {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    window.webkit.messageHandlers.getDataFormVue.postMessage({
      title: "333", //vue给iOS传值
    });
  } else {
    window.android.onFinish();
  }
};
onMounted(() => {
  GetProjtotal();
});
</script>

<style scoped>
.projectName {
  color: rgb(50, 92, 254);
}
:deep(.van-tabs__line) {
  background: white !important;
}

.van-nav-bar {
  background-color: rgb(50, 92, 254);
}

:deep(.van-nav-bar .van-icon) {
  color: white !important;
}

:deep(.van-nav-bar__title) {
  color: white !important;
}

:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>